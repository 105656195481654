import React, { useState, useEffect } from "react";
import styles from "./Navigation.module.css";
import logo from "./assets/garba-logo.svg";
import garbaTie from "./assets/garba-tie.svg";
import { AnimatePresence, motion } from "framer-motion";
import {Link, useLocation} from "react-router-dom";

const Navigation = ({ scrollToChildDiv }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const location = useLocation();

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  // Add scroll event listener
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Determine the style based on the current path
  const isBasePath = location.pathname === "/"; // Adjust this condition if your base path is different
  const navClass = `${styles.nav} ${isScrolled ? styles.scrolled : ""} ${
      isBasePath ? styles.basePath : styles.otherPath
  }`;

  return (
      <nav className={navClass}>
        <div className="container">
          <div className={styles.navContent}>
            <div className={styles.logo}>
              <img src={logo} alt="Garba Logo" className={styles.logoImage} />
            </div>
            <ul className={styles.navList}>
              <li>

                <Link
                    to={"/"}
                    className={styles.underlineFill}
                    onClick={(e) => {
                      scrollToChildDiv();
                    }}
                >
                 <div>Product</div>
                </Link>
              </li>
              <li>
                <a className={styles.underlineFill} href="https://8i07v9gsfzp.typeform.com/to/Fcb8GpMK" target="_blank">
                  Pricing
                </a>
              </li>
              <li>
                <a className={styles.underlineFill} href="https://8i07v9gsfzp.typeform.com/to/oLd0BUNy" target="_blank">
                  About us
                </a>
              </li>
            </ul>
            <ul className={styles.navList} style={{ marginLeft: "auto" }}>
              <li>
                <a className={styles.underlineFill}  href="https://8i07v9gsfzp.typeform.com/to/oLd0BUNy" target="_blank">
                  Request early access
                </a>
              </li>
              <li className={styles.special}>
                <a href="https://app.garba.ai">
                  <span>Login</span>
                  <div>
                    <img src={garbaTie} alt="Garba Logo" className={styles.logoImage}/></div>
                </a>
              </li>
            </ul>
            <button className={styles.hamburger} onClick={toggleModal}>
              ☰
            </button>
            <AnimatePresence>
              {isModalOpen && (
                  <motion.div
                      initial={{ translateY: "-100%" }}
                      animate={{ translateY: 0, transition: { ease: "easeOut", duration: 0.5 } }}
                      exit={{ translateY: "100%", transition: { ease: "easeIn", duration: 0.5 } }}
                      className={styles.modal}
                  >
                    <div className={styles.modalOverlay} onClick={closeModal}></div>
                    <div className={styles.modalContent}>
                      <ul className={styles.modalNavList}>
                        <li>
                          <a
                              href="#"
                              onClick={(e) => {
                                e.preventDefault();
                                scrollToChildDiv();
                                closeModal();
                              }}
                          >
                            Product
                          </a>
                        </li>
                        <li>
                          <a href="https://8i07v9gsfzp.typeform.com/to/Fcb8GpMK" target="_blank" onClick={closeModal}>
                            Pricing
                          </a>
                        </li>
                        <li>
                          <a href="https://8i07v9gsfzp.typeform.com/to/oLd0BUNy" target="_blank" onClick={closeModal}>
                            About us
                          </a>
                        </li>
                        <li>
                          <a href="https://8i07v9gsfzp.typeform.com/to/oLd0BUNy" target="_blank" onClick={closeModal}>
                            Request early access
                          </a>
                        </li>
                        <li className={styles.specialModal}>
                          <a href="https://app.garba.ai" onClick={closeModal}>
                            <span>Login</span>
                          </a>
                        </li>
                        <li className={styles.special2}>
                          <span onClick={closeModal}>Close</span>
                        </li>
                      </ul>
                    </div>
                  </motion.div>
              )}
            </AnimatePresence>
          </div>
        </div>
      </nav>
  );
};

export default Navigation;
